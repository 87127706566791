import { SignInButton, SignUpButton, SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { AnimatedPrimaryButton, RaisinLink } from "components/misc/Buttons.js";
import { SectionHeading } from "components/misc/Headings.js";
import { Container as ContainerBase, Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { SectionDescription, SocialProofBadge } from "components/misc/Typography.js";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled from "styled-components";
import tw from "twin.macro";
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLink as NavLinkBase,
  NavLinks,
  NavAnchorLink,
  PrimaryLink as PrimaryLinkBase
} from "./headers/light.js";

import { Suspense, lazy } from "react";
import { EmailOverlay } from "./misc/EmailOverlay.js";

const MoneyImage = lazy(() => import("./images/MoneyImage.js"));
const LogoImage = lazy(() => import("./images/LogoImage.js"));

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-gray-100 text-gray-900 relative`;

const TrialBadge = styled.div`
  ${tw`absolute top-0 right-0 bg-primary-500 text-white font-bold py-2 px-4 rounded-bl-lg shadow-lg z-50`}
  transform: rotate(5deg);
  &:before {
    content: "60-DAY FREE TRIAL";
  }
`;



const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-900 lg:hocus:text-gray-700 lg:hocus:border-gray-900`;
const LogoLink = tw(LogoLinkBase)`text-gray-900 hocus:text-gray-700 pr-4`;
const PrimaryLink = tw(PrimaryLinkBase)`cursor-pointer shadow-raised lg:bg-orange-500 lg:hocus:bg-orange-700`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = styled(Column)`
  ${tw`relative mt-16 lg:mt-0 lg:ml-16 overflow-hidden isolate`}
`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 mb-8 max-w-2xl text-gray-900 lg:text-base mx-auto lg:mx-0`;

const ButtonContainer = tw.div`flex justify-center`;
const BadgeContainer = tw.div`flex justify-center`;

export default () => {
  const logoLink = (
    <LogoLink href="/">
      <Suspense><LogoImage /></Suspense>
      <h1>Yield Alarm <em><small> &nbsp;for Raisin</small></em></h1>
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <SignedOut>
        <NavAnchorLink><AnchorLink href="#features">Features</AnchorLink></NavAnchorLink>
        <NavAnchorLink><AnchorLink href="#pricing">Pricing</AnchorLink></NavAnchorLink>
        <NavAnchorLink><AnchorLink href="#faq">FAQ</AnchorLink></NavAnchorLink>
        <SignInButton redirectUrl={process.env.REACT_APP_BASE_URL} mode="modal"><NavLink href="#">Login</NavLink></SignInButton>
        <SignUpButton redirectUrl={process.env.REACT_APP_BASE_URL} mode="modal"><PrimaryLink>Start Free Trial</PrimaryLink></SignUpButton>
      </SignedOut>
      <SignedIn>
        <UserButton afterSignOutUrl={process.env.REACT_APP_BASE_URL} userProfileMode="modal" showName />
      </SignedIn>
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer>
      <SignedOut><TrialBadge /></SignedOut>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <SignedOut>
          <Container>
            <ContentWithVerticalPadding>
              <Row>
                <TextColumn>
                  <Heading>Get Higher Interest Rates on Your Savings</Heading>
                  <Description>Maximize your savings account interest with a few clicks. Get instant alerts when better high-yield savings account rates become available on <RaisinLink />.</Description>
                  <ButtonContainer>
                    <SignUpButton mode="modal">
                      <AnimatedPrimaryButton as="a">Start Free Trial</AnimatedPrimaryButton>
                    </SignUpButton>
                  </ButtonContainer>
                  <BadgeContainer>
                    <SocialProofBadge>$100,000+ in deposits optimized</SocialProofBadge>
                  </BadgeContainer>
                </TextColumn>
                <IllustrationColumn>
                  <Suspense><MoneyImage /><EmailOverlay /></Suspense>
                </IllustrationColumn>
              </Row>
            </ContentWithVerticalPadding>
          </Container>
        </SignedOut>
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};
