import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components/macro"; //eslint-disable-line
import tw from "twin.macro";
import { ExternalLink, RaisinLink } from "./misc/Buttons";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-900`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-900 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-700`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw(motion.div)`relative select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 hover:bg-gray-200 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 px-4 text-gray-700`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "Everything you ever wanted to know about Yield Alarm for Raisin",
  faqs = [
    {
      question: "What is Yield Alarm?",
      answer: (
        <>
          Yield Alarm scours Raisin (formerly SaveBetter)'s network of banks and credit unions, and notifies you when higher interest rates become available. Then simply login to Raisin and move your funds to the higher-APY account.
        </>
      )
    },
    {
      question: "Why does Yield Alarm only work with Raisin (formerly SaveBetter)?",
      answer: (
        <>
          The biggest hassle in chasing high yield savings accounts is the annoying process of opening accounts at new institutions. <RaisinLink>Raisin</RaisinLink> makes that easy by letting you open an account at a new institution in just a few clicks. Nobody else offers that.
        </>
      )
    },
    {
      question: "How does Raisin + Yield Alarm compare to Titan Smart Treasury?",
      answer: (
        <>
          <ExternalLink href='https://www.titan.com/smart-treasury'>Titan Smart Treasury</ExternalLink> automatically moves your money to higher-yield options for you, so you don't need to manage it. However, they charge an advisory fee <em>and</em> a membership fee which makes their service much more expensive than Yield Alarm in almost all cases.
        </>
      )
    },
    {
      question: "Why care about APY changes?",
      answer: (
        <>
          Whenever the top APY changes, you should consider moving your funds to the higher-APY account. As a simple example, imagine you opened a 5% APY savings account with $10,000 and deposit no additional funds. After one month, your bank sneakily drops the rate to 4.5% while another offers 5.25%. If you don't move your funds, at the one-year mark, you'll end up with ~$10,454. If you move your funds to the new bank, you'll have ~$10,523, an increase of about $69. This difference affects the amount that compounds over many years and is even greater if you're contributing additional savings to the account.
        </>
      )
    },
    {
      question: "How can I cancel?",
      answer: (
        <>
          You can easily cancel any time by logging in and clicking cancel. You don't need to contact us, although you're welcome to if you'd like help. Your subscription will continue through the end of the prepaid billing period.
        </>
      )
    },
    {
      question: "How do I switch to a higher-APY account?",
      answer: (
        <>
          You can transfer funds directly between accounts within Raisin using the new Cash Account feature, making it faster and easier to manage your savings. Most transfers complete by the next business day.
        </>
      )
    },
    {
      question: "What's the difference between APY and APR?",
      answer: (
        <>
          APR is your account's Annual Percentage Rate, but it doesn't take into account more frequent (i.e., daily or monthly) compounding. APY (Annual Percentage Yield) takes compounding into account to show you the real rate of return you'll earn on an investment. APY gives you an accurate measure of how much your money will grow in 1 year. Since different accounts can compound at different rates, Yield Alarm compares them based on APY and not APR.
        </>
      )
    },
    {
      question: "Who should sign up for Yield Alarm?",
      answer: (
        <>
          Yield Alarm is more valuable if you have more cash saved and if higher-APY options are identified that you wouldn't have noticed on your own. You should sign up for it if you don't check your APY regularly and you have a significant amount of cash. You should not sign up if you have a small enough amount of cash that interest rate fluctuation is unlikely to exceed the subscription cost.
        </>
      )
    },
    {
      question: "What is the 30-day money-back guarantee?",
      answer: (
        <>
          If Yield Alarm doesn't meet your expectations within 30 days of being charged (when the 60-day trial ends), email <ExternalLink href='mailto:support@yieldalarm.com'>support@yieldalarm.com</ExternalLink> for a refund. Refunds are processed manually and may take 7–10 business days to reflect in your account.
        </>
      )
    },
    {
      question: "Where can I reach you for support?",
      answer: (
        <>
          You can email <ExternalLink href='mailto:support@yieldalarm.com'>support@yieldalarm.com</ExternalLink> with any additional questions.
        </>
      )
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq
        key={index}
        onClick={(e) => {
          if (e.target.tagName !== 'A') {
            toggleQuestion(index);
          }
        }}
        variants={{
          open: { backgroundColor: tw`bg-gray-200`.backgroundColor, transition: { duration: 0.1 } }, // Tailwind gray background when open
          collapsed: { backgroundColor: tw`bg-primary-900`.backgroundColor, transition: { duration: 0.1 } } // Transparent background when closed
        }}
        initial="collapsed"
        animate={activeQuestionIndex === index ? "open" : "collapsed"}
      >
        <Question>
          <QuestionText
            css={activeQuestionIndex === index ? tw`px-4 transition-all duration-300` : tw`pl-0 pr-8 transition-all duration-300`}
          >
            {faq.question}
          </QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0, marginRight: "8px" },
              open: { rotate: 90, marginRight: "8px" }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer id="faq" >
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
