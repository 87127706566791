// EmailOverlay.js
import { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { RaisinLink } from './Buttons';

const OverlayContainer = styled.div`
  ${tw`absolute inset-0 flex items-center justify-center max-h-full max-w-full`}
`;

const EmailCard = styled.div`
  ${tw`bg-gray-100/90 p-6 rounded-lg shadow-lg w-full mx-4 overflow-y-auto text-xs`}
  min-height: 98%;
  max-height: 98%;
  max-width: calc(100% - 2rem);
`;

const TabContainer = styled.div`
  ${tw`flex mb-4 border-b border-gray-300`}
`;

const Tab = styled.button`
  ${tw`px-4 py-2 font-bold transition-colors duration-200`}
  ${({ active }) => active && tw`border-b-2 border-primary-100 text-primary-100`}
`;

const MonospaceText = styled.div`
  font-family: "SF Mono", Menlo, Consolas, "Roboto Mono", "DejaVu Sans Mono", monospace;
`;

const EmailHeader = styled(MonospaceText)`
  ${tw`mb-2`}
`;

const EmailField = styled.div`
  span {
    ${tw`font-bold`}
  }
`;

const EmailBody = styled(MonospaceText)`
  ${tw`mt-2 whitespace-pre-line leading-tight`}
`;

const HighlightedText = styled.p`
  ${tw`font-bold mb-4`}
`;

const SectionHeader = styled.h2`
  ${tw`text-base font-medium mb-2`}
`;

export const EmailOverlay = () => {
  const [activeTab, setActiveTab] = useState('up');

  const emailContent = {
    up: {
      highlight: 'Yield Alarm detected a rate increase to 4.55%.',
      body: `The best rate went up from 4.15% to 4.55%.

The best bank(s): Woodgrove
New to the best APY group: Woodgrove
These banks are no longer offering the best APY: Iron Bank, Bank of Orion`
    },
    down: {
      highlight: 'Yield Alarm detected a rate decrease to 4.45%.',
      body: `The best rate went down from 4.50% to 4.45%.

The best bank(s): Iron Bank, Woodgrove
New to the best APY group: Woodgrove
These banks are no longer offering the best APY: Bank of Orion`
    },
    dropped: {
      highlight: 'Yield Alarm detected fewer banks offering 4.45%.',
      body: `The best rate stayed the same at 4.45%, but fewer banks are offering it. If your bank was removed from the list, consider switching.

The best bank(s): Bank of Orion, Woodgrove
These banks are no longer offering the best APY: Iron Bank`
    }
  };

  return (
    <OverlayContainer>
      <EmailCard>
        <SectionHeader>Sample E-mail Notifications</SectionHeader>
        <TabContainer>
          <Tab active={activeTab === 'up'} onClick={() => setActiveTab('up')}>
            Rate Increase
          </Tab>
          <Tab active={activeTab === 'down'} onClick={() => setActiveTab('down')}>
            Rate Decrease
          </Tab>
          <Tab active={activeTab === 'dropped'} onClick={() => setActiveTab('dropped')}>
            Banks Dropped
          </Tab>
        </TabContainer>
        <EmailHeader>
          <EmailField><span>To:</span> yourname@gmail.com</EmailField>
          <EmailField><span>From:</span> Yield Alarm &lt;support@yieldalarm.com&gt;</EmailField>
          <EmailField><span>Subject:</span> 🚨 Yield Alarm detected a rate change</EmailField>
        </EmailHeader>
        <hr />
        <EmailBody>
          <HighlightedText>{emailContent[activeTab].highlight}</HighlightedText>
          {emailContent[activeTab].body}
          <br /><br />
          <p>Login to <RaisinLink /> to move your funds.</p>
        </EmailBody>
      </EmailCard>
    </OverlayContainer>
  );
};
