import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription, SocialProofBadge } from "components/misc/Typography.js";

import colors from 'tailwindcss/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faEnvelopeOpenText, faHandshakeSimple } from '@fortawesome/free-solid-svg-icons';
import { SignUpButton } from "@clerk/clerk-react";
import { AnimatedPrimaryButton } from "./misc/Buttons";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-900`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-10 md:py-12`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-900`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-700`;

const VerticalSpacer = tw.div`mt-8 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-4 transition duration-300 rounded-lg`}
  &:hover {
    ${tw`bg-gray-100 shadow-xl`}
  }
  .imageContainer {
    ${tw`bg-primary-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`font-medium text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-700 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Maximize Your Savings",
  subheading = "",
  description = "Effortlessly grow your savings with our subscription service, ensuring you never miss higher interest rates."
}) => {
  const defaultCards = [
    {
      icon: faEnvelopeOpenText,
      title: "Email Alerts",
      description: "Receive email alerts for higher-APY accounts."
    },
    {
      icon: faBolt,
      title: "Daily Monitoring",
      description: "We monitor APY changes daily."
    },
    {
      icon: faHandshakeSimple,
      title: "Flexible Control",
      description: "Pause or cancel anytime."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="features">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <div tw="flex justify-center w-full">
          <SocialProofBadge>6,000+ notifications sent</SocialProofBadge>
        </div>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <FontAwesomeIcon icon={card.icon} color={colors.white} size="xl" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
        <VerticalSpacer />
        <div tw="flex justify-center w-full">
          <SignUpButton mode="modal">
            <AnimatedPrimaryButton as="a">Start Trial Now</AnimatedPrimaryButton>
          </SignUpButton>
        </div>
      </ThreeColumnContainer>

    </Container>
  );
};
