import tw from "twin.macro";
import styled from "styled-components";
export const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg text-secondary-100 max-w-xl`;
export const Text = styled.div`
${tw`text-lg  text-gray-700`}
p {
  ${tw`mt-2 leading-loose`}
}
h1 {
  ${tw`text-3xl font-bold mt-10`}
}
h2 {
  ${tw`text-2xl font-bold mt-8`}
}
h3 {
  ${tw`text-xl font-bold mt-6`}
}
ul {
  ${tw`list-disc list-inside`}
  li {
    ${tw`ml-2 mb-3`}
    p {
      ${tw`mt-0 inline leading-normal`}
    }
  }
}
`;
export const SocialProofBadge = styled.div`
  ${tw`mt-8 inline-flex items-center px-4 py-2 rounded-full bg-primary-100 text-primary-700 hover:text-gray-100 text-sm font-semibold shadow-lg hover:shadow-gray-100 transition duration-100 ease-in-out`} 
  &:before {
    content: "💚";
    margin-right: 8px;
  }
`;
