import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription, SocialProofBadge } from "components/misc/Typography.js";
import { AnimatedPrimaryButton } from "components/misc/Buttons.js";
import { SignUpButton } from "@clerk/clerk-react";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";

const Container = tw(ContainerBase)`bg-gray-200 text-gray-900 -mx-8 px-8 pb-8 flex flex-col items-center`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-700 text-center`;

const Plan = styled.div`
  ${tw`mt-4 w-full max-w-sm bg-gray-100 rounded-lg shadow-lg py-6 px-4 sm:px-8 lg:px-6 lg:py-8 xl:p-8 mx-3 flex flex-col justify-between transition-transform duration-300 hover:shadow-xl hover:bg-white`}
`;

const PlanFeatures = styled.ul`
  ${tw`flex-1 py-4 px-4 sm:px-8`}
  .feature {
    ${tw`flex items-center mt-4 first:mt-0`}
    &:hover {
      .icon, .text {
        ${tw`text-primary-200`}
      }
    }
    .icon {
      ${tw`w-5 h-5 text-primary-100 flex-shrink-0`}
    }
    .text {
      ${tw`text-primary-100 ml-2 leading-5`}
    }
  }
`;

const PlanAction = tw.div`mt-4 flex justify-center`;

export default () => {
  return (
    <Container id="pricing">
      <HeaderContainer>
        <Heading>Minimize Costs</Heading>
        <Description>Start with a <strong>60-day free trial</strong>. Then just <strong>59¢/month</strong>, billed annually at $6.99. Plus a 30-day money-back guarantee.</Description>
      </HeaderContainer>
      <Plan>
        <PlanFeatures>
          {[
            "Daily rate scans",
            "Unlimited email alerts",
            "Often pays for itself",
            "60-day free trial",
            "30-day money-back guarantee"
          ].map((feature, index) => (
            <li className="feature" key={index}>
              <CheckboxIcon className="icon" />
              <span className="text">{feature}</span>
            </li>
          ))}
        </PlanFeatures>
        <PlanAction>
          <SignUpButton mode="modal" redirectUrl="/">
            <AnimatedPrimaryButton as="a">
              Start Free Trial
            </AnimatedPrimaryButton>
          </SignUpButton>
        </PlanAction>
      </Plan>
      <SocialProofBadge>30-Day Money-Back Guarantee</SocialProofBadge>
    </Container>
  );
};
