import { SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import { useState } from 'react';
import Checkout from "components/Checkout.js";
import FAQ from "components/FAQ.js";
import Features from "components/Features.js";
import Footer from "components/Footer.js";
import Hero from "components/Hero.js";
import Pricing from "components/Pricing.js";
import { PrimaryButton } from "components/misc/Buttons";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {
  return (
    <StyledDiv>
      <Hero />
      <SignedOut>
        <Features />
        <Pricing />
        <FAQ />
      </SignedOut>
      <SignedIn>
        <SignedInContent />
      </SignedIn>
      <Footer />
    </StyledDiv>
  );
}


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-sm mx-auto py-20 lg:py-24`;
const VerticalSpacer = tw.div`mt-10 w-full`
const PrimaryText = tw.div`mt-4 text-primary-500 text-2xl`

const SignedInContent = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { isLoaded, isSignedIn, user } = useUser();
  if (!isLoaded || !isSignedIn) {
    return null;
  }

  console.log("USER = ", user);

  const createCustomerId = async () => {
    try {
      const response = await fetch("/.netlify/functions/create-customer", { method: "POST", headers: { "ngrok-skip-browser-warning": true } });
      if (response.ok) {
        user.reload();
      } else {
        handleError(Error(response.status + response.statusText))
      }
    } catch (error) {
      handleError(error);
    }
  };

  if (user.publicMetadata.stripeCustomerID) {
    console.log("customer id ", user.publicMetadata.stripeCustomerID);
  } else if (!errorMessage) {
    console.log("creating…")
    createCustomerId();
  }

  const handleError = (error) => {
    console.log("error ", error);
    setErrorMessage(error.message);
  }

  let introText;
  let Component;
  if (user.publicMetadata.stripeSubscriptionID) {
    introText = "While your subscription is active, you‘ll receive e-mails from support@yieldalarm.com whenever the rates on Raisin change. To manage your subscription, click the button below.";
    Component = () => <PrimaryButton as="a" href="/.netlify/functions/create-customer-portal-session">Subscription Portal</PrimaryButton>
  } else if (errorMessage) {
    introText = "Error: " + errorMessage;
    Component = () => <PrimaryButton onClick={() => setErrorMessage(null)}>Retry</PrimaryButton>;
  } else if (!user.publicMetadata.stripeCustomerID) {
    introText = "Loading…";
    Component = () => null;
  } else {
    introText = "To start your free 60-day trial, enter your payment information below. If you keep your subscription, $6.99 will be securely processed by Stripe each year. You'll get an e-mail reminder 7 days before your trial ends.";
    Component = () => <Checkout />;
  }

  return <Container>
    <Content>
      <PrimaryText>Welcome{user.firstName && " " + user.firstName}!</PrimaryText>
      <SectionDescription>{introText}</SectionDescription>
      <VerticalSpacer />
      <Component />
    </Content>
  </Container>

}
