import tw from "twin.macro";
import styled, { keyframes } from "styled-components";

export const LinkStyle = tw.a`text-primary-100 hover:underline`;
export const ExternalLink = ({ href, children }) => <LinkStyle href={href} target="_blank" rel="noopener noreferrer">{children}</LinkStyle>;
export const RaisinLink = () => <ExternalLink href="https://raisin.com">Raisin</ExternalLink>;

export const PrimaryButton = tw.button`cursor-pointer px-8 py-3 font-bold rounded bg-orange-500 text-gray-100 hocus:bg-orange-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition ease-in-out`;

const pulseKeyframes = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
`;

const popKeyframes = keyframes`
  0% {
    transform: scale(1);
  }
  50% { transform: scale(1.25); }
  100% {
    transform: scale(1.2);
  }
`;

export const AnimatedPrimaryButton = styled(PrimaryButton)`
  animation: ${pulseKeyframes} 2s ease-in-out infinite;
  
  &:hover {
    animation: ${popKeyframes} 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  }
`;